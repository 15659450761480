@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

.b-header {
  font-family: $font-family-regular;
  letter-spacing: 0.02em;

  &__container {
    display: flex;
    justify-content: space-between;
    padding: 32px 0 24px;
    align-items: center;
  }

  &__town {
    flex: 1;
  }

  &__logo {
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &__logo-img {
    background-position: center;
    background-repeat: no-repeat;
    text-decoration: none;
    display: block;
    margin: 0;
    height: 36px;
    width: 236px;
    background-size: contain;
  }

  &__logo-stat {
    font-size: $font-size-body-1;
    color: $ui-kit-text-secondary;
    text-align: center;
    font-weight: $weight-medium;
    margin-top: 2px;
  }

  &__account {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__search {
    margin-bottom: 24px;
    z-index: 110;
    position: relative;
  }

  .cart-icon {
      position: relative;
  }

  .add-point-to-cart {
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 10px;
    }
}
